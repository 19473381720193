import React from 'react';
import footer_sns_discord from '../images/footer_sns_discord.svg';
import footer_sns_twitter from '../images/footer_sns_twitter.svg';
import footer_sns_telegram from '../images/footer_sns_telegram.svg';

const SocialLinks: React.FC<{}> = (props) => {
  return (
    <>
      <a href="https://t.me/cakemnstr" target="_blank" className="telegram-icon social-icon">
        <img src={footer_sns_telegram} alt={'Telegram'} className="w-10" />
      </a>
      <a href="https://twitter.com/MonstaPartyNFTs" target="_blank" className="twitter-icon social-icon">
        <img src={footer_sns_twitter} alt={'Twitter'} className="w-10" />
      </a>
      <a href="https://discord.com/invite/MonstaPartyNFTs" target="_blank" className="discord-icon social-icon">
        <img src={footer_sns_discord} alt={'Discord'} className="w-10" />
      </a>
    </>
  );
};

export default SocialLinks;
