import React from 'react';
import SocialLinks from '../../components/SocialLinks';
import footer_left from '../../images/footer_left.svg';
import footer_right from '../../images/footer_right.svg';
import footer_balloon from '../../images/footer_balloon.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Footer: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div id="community" className="w-full flex flex-col items-center justify-evenly mb-10">
        <h2 className="text-3xl font-medium mt-20 font-super text-white uppercase">
          {t('Footer.joinus', 'Join Us')}
        </h2>
        <div className="flex space-x-4 md:space-x-6 text-4xl py-6 -mt-2">
          <img
            src={footer_left}
            alt={t('Footer.joinus', 'Join Us')}
            className="w-16 md:w-20 -mt-10"
          />
          <SocialLinks />
          <img
            src={footer_right}
            alt={t('Footer.joinus', 'Join Us')}
            className="w-16 md:w-20 -mt-10 "
          />
        </div>
        <a className="text-lg underline hover:text-yellow-400" href="mailto: contact@monsta.party">
          {t('Footer.contactUs', 'Contact Us!')}
        </a>
      </div>
      <div className="hidden w-1/4 md:block absolute right-0 h-96 -mt-96">
        <img src={footer_balloon} alt={t('Footer.joinus', 'Join Us')} className="h-full w-auto" />
      </div>
    </>
  );
};

export default Footer;
